import React, { Component } from 'react'
// import { Link } from 'gatsby'
import _ from 'lodash'

import { withNamespaces } from 'react-i18next'

import LocalImage from '../../components/local-image'


class UnitsList extends Component {
    constructor(props) {
        super(props)
        // Don't call this.setState() here!

        this.state = {
            list: null
        }
    }

    async componentDidMount() {

        let self = this
        // console.log(`===== UnitsList Compontent > componentDidMount self.props ====`, self.props)

        const t = self.props.t
        const enTran = self.props.enTran
        const lang = self.props.lng
        const mode = self.props.mode
        const data = (mode === 'all') ? { "all" : self.props.unitslist } : _.groupBy(self.props.unitslist, 'TypeName')
        // console.log(`===== mode ${mode} ===== data group ( ${_.size(data)} ) ========== `, data)

        const typelist = {
            "Heroes":       t("PT5001"),
            "Infantry":     t("PT5002"),
            "SA":           t("PT5003"),
            "Others":       t("PT5006"),
        }

        let typeorder = (mode === 'all') ? ["all"] : self.props.typeorder || ["Heroes","Infantry","SA","Others"]
        // console.log(`> typeorder ( ${_.size(typeorder)} ) = `, typeorder)

        // Display by Group 
        let htmlCompontent = []
        _.map( typeorder , (type, i) => {
            if ( _.size(data[type]) > 0 ){
                // console.log(`typeorder[${i}] > data[${type}] ( ${_.size(data[type])} ) = `, data[type])

                // Printer header for non-single group 
                if ( _.size(typeorder) > 1 && mode !=="all")      htmlCompontent.push( <div key={`unit-header-${i}`} className="section-line-head">{`${typelist[type]}`}</div> )

                // Loop section content
                htmlCompontent.push(
                    <div key={`unit-thumbbox-${i}`} className="thumbbox d-flex justify-content-start flex-wrap">
                    {_.map( data[type] , (item,i) => {
                        let enName = enTran[item.Name] || item.UnitID
                        // console.log(`enName = ${enName}`)
                        let slug = (enName)? enName.match(/[A-Za-z0-9]+/g).join('_').toLowerCase() : item.UnitID
                        // console.log(` data[${type}]${i} - item.NameEn = ${item.NameEn} , slug = ${slug}`)
                        
                        return (
                            <a key={`unit-link-${i}`} href={`/${lang}/database/${item.TypeName.toLowerCase()}/${slug}`} rel="noopener" target="_blank">
                                <figure>
                                    <div className="figure-img-container text-center">
                                    {
                                        (typeof item.Thumbnail === undefined || item.Thumbnail === null || item.Thumbnail === "" ) ? 
                                            null
                                            :
                                            <LocalImage name={`${item.Thumbnail.split('.')[0]}`} attr={{className: "img-fluid lazyloaded", alt:`${t(item.Name)} Thumbnail`}}/>
                                    }
                                    </div>
                                    <figcaption className="figure-caption">{t(item.Name)}</figcaption>
                                </figure>
                            </a>
                        )
                    })}
                    </div>
                )

            }
        })

        // console.log(`list = `, list)
        // this.setState({  list :  list  })
        this.setState({  list :  htmlCompontent  })
    }

    render() {
        // console.log(`===== UnitsList Compontent > this.props ====`)
        // console.log(this.props)

        return (
            this.state.list 
        )
    }
}

export default withNamespaces()(UnitsList)
