import React, { Component } from 'react'
// import { Link } from 'gatsby'
import _ from 'lodash'

import {
    Dropdown, Col
} from 'react-bootstrap'

import { withNamespaces } from 'react-i18next'

import LocalImage from '../../components/local-image'


class UnitsMenu extends Component {
    // constructor(props) {
    //     super(props)
    //     // Don't call this.setState() here!
    // }

    render() {

        // console.log(` ==== this.props : UnitsMenu ==== `, this.props )

        const t = this.props.t
        const lang = this.props.lng
        const mode = this.props.mode
        const pages = {
            "all":          ["PT5000",  `/${lang}/database/`],
            "heroes":       ["PT5001",  `/${lang}/database/heroes`],
            "infantry":     ["PT5002",  `/${lang}/database/infantry`],
            "sa":           ["PT5003",  `/${lang}/database/sa`],
            "others":       ["PT5006",  `/${lang}/database/others`],
            "divider":      [],
            "faction":      ["PT5004",  `/${lang}/database/faction`],
            "divisions":    ["PT5005",  `/${lang}/database/divisions`],
        }

        let htmlCompontent = []
        switch (mode) {
            case 'dropdown':
                htmlCompontent.push(
                    <Col key="units-menu-dropdown-col" xs={3} className="d-block d-md-none px-0">
                        <Dropdown className="submenu d-flex justify-content-center" alignRight>
                            <Dropdown.Toggle variant="bookmark">
                                <LocalImage name="btn-down" attr={{className: "icon", alt:"Lang"}} fluid={true}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {_.map( pages, (data,key) =>{
                                    if (key==='divider'){
                                        return(<Dropdown.Divider key={`units-menu-dropdown-${key}`} />)
                                    }else{
                                        return(
                                            <Dropdown.Item key={`units-menu-dropdown-${key}`} href={data[1]}>{t(data[0])}</Dropdown.Item>
                                        )
                                    }
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                )
                break

            case 'tab':
            default:
                htmlCompontent.push(
                    <Col key="units-menu-tab-col" className="d-none d-md-block">
                        <div className="tabMenu d-flex justify-content-center">
                        {_.map( pages, (data,key) =>{
                            if (key!=='divider'){
                                let active = (this.props.active===key) ? 'active':''
                                return(
                                    <a className={active} href={data[1]} key={`units-menu-tab-${key}`} rel="noopener ">{t(data[0])}</a>
                                )
                            }
                        })}
                        </div>
                    </Col>
                )
        }
        return(htmlCompontent)
    }
}

export default withNamespaces()(UnitsMenu)
